// @ts-nocheck
/* eslint-enable */
import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import * as S from './styles';

const { bool, func, element, string, oneOf, any } = PropTypes;

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'text-color'
  | 'text-color-outline'
  | 'text-color-light'
  | 'default'
  | 'warning'
  | 'alert-secondary'
  | 'primary-outline'
  | 'primary-inactive'
  | 'dark';

type ButtonSize = 'sm' | 'md' | 'lg';
type IconGap = '' | 'none' | 'xs' | 'sm';

interface ButtonV2Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Button type/style variant */
  btnType?: ButtonType;
  /** Bold text */
  bold?: boolean;
  /** Full width button */
  full?: boolean;
  /** HTML tag to render as */
  htmlTag?: string;
  /** Rounded corners */
  rounded?: boolean;
  /** Hide input border */
  hideInputBorder?: boolean;
  /** Position in input group */
  inputButtonGroupPosition?: string | null;
  /** Button size */
  size?: ButtonSize;
  /** Icon element */
  icon?: React.ReactElement;
  /** Gap between icon and text */
  iconGap?: IconGap;
}

const ButtonV2 = React.forwardRef<HTMLButtonElement, ButtonV2Props>((props = {}, ref) => {
  const rest = omit(props, [
    'bold',
    'btnType',
    'children',
    'className',
    'dispatch',
    'full',
    'hideInputBorder',
    'inputButtonGroupPosition',
    'rounded',
    'size',
    'htmlTag',
    'icon',
    'iconGap',
    'onClick',
  ]);

  const {
    bold,
    btnType,
    children,
    className,
    disabled,
    full,
    hideInputBorder,
    htmlTag: CustomTag,
    inputButtonGroupPosition,
    rounded,
    size,
    icon,
    iconGap,
    onClick,
  } = props;

  return (
    <S.Button
      {...rest}
      as={CustomTag}
      size={size}
      full={full}
      inputButtonGroupPosition={inputButtonGroupPosition}
      hideInputBorder={hideInputBorder}
      btnType={btnType}
      bold={bold}
      disabled={disabled}
      rounded={rounded}
      className={className}
      ref={ref}
      onClick={onClick}
    >
      <S.ButtonContentContainer iconGap={iconGap}>
        {icon}
        {children}
      </S.ButtonContentContainer>
    </S.Button>
  );
});

ButtonV2.propTypes = {
  btnType: oneOf([
    'primary',
    'secondary',
    'tertiary',
    'text-color',
    'text-color-outline',
    'text-color-light',
    'default',
    'warning',
    'alert-secondary',
    'primary-outline',
    'primary-inactive',
    'dark',
  ]),
  children: any,
  className: string,
  disabled: bool,
  full: bool,
  htmlTag: string,
  rounded: bool,
  hideInputBorder: bool,
  inputButtonGroupPosition: string,
  size: oneOf(['sm', 'md', 'lg']),
  bold: bool,
  icon: element,
  iconGap: oneOf(['', 'none', 'xs', 'sm']),
  onClick: func,
};

ButtonV2.defaultProps = {
  btnType: 'primary',
  children: null,
  className: '',
  disabled: false,
  htmlTag: 'button',
  full: false,
  rounded: false,
  hideInputBorder: false,
  inputButtonGroupPosition: null,
  size: 'md',
  bold: false,
  icon: null,
  iconGap: 'sm',
  onClick: () => {},
};

ButtonV2.displayName = 'ButtonV2';

export type { ButtonV2Props, ButtonType, ButtonSize, IconGap };
export default ButtonV2;
